button.btn.btn-default.pdf-button {
    margin-right: 20px;
    font-size: 18px;
}
.modal-filter select {
    width: 200px;
}
.modal-filter select {
    height: 40px;
}
.prev-next button {
    margin: 0 10px;
}
.modal-lg {
    width: 1341px;
}
.result-tab li {
    padding: 0 5px;
}
.result-tab li:first-child {
     padding-left: 0;
 }

.result-tab li:last-child {
     padding-right: 0;
 }
.result-tab li button {
    width: 121px;
    padding: 1px 12px;
    border-bottom: 0;
    margin-bottom: 0px;
    border-radius: 4px 4px 0 0;
}
.result-tab li button:focus{
    outline: none !important;
    border: 1px solid #999;
    border-bottom: 0;
}
.datewise-result .btn-default.active{
    background: #205493;
    color: #fff;
}
.margin-top-10{
    margin-top: 10px;
}
#detailsModal .modal-body{
    min-height: 500px;
}
