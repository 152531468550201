/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.react-datepicker{
    font-size: 14px !important;
}
table.table.cc {
    min-width: 2000px;
}
.scroll-inner-check {
    height: 170px;
    overflow: scroll;
}
.scroll-inner-check .d-flex{
    padding: 10px 0;
}
.advnaced .scroll-inner-check {
    height: 300px;
}


.custom-buttons button {
    margin-right: 10px !important;
    font-weight: 500;
    padding: 8px 20px;
    box-shadow: 0 0 1px #ddd;
}
.d-flex.right-buttons button {
    margin-right: 10px;
    box-shadow: 0 0 1px #ddd;
}

.position-abs.collapsible-filter {
    width: 650px;
    right: -40px;
    top: 120px;
    background: #fff;
    padding: 17px 25px;
    border-radius: 7px;
    box-shadow: 0 0 1px #999;
    position: absolute;
    z-index: 9999999 !important;
    visibility: hidden;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: .2s cubic-bezier(0, 0.8, 1, 1.17);
    transition: .2s cubic-bezier(0, 0.8, 1, 1.17)
}
.position-abs.collapsible-filter.active{
    visibility: visible;
    opacity:1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    top: 55px;
}

.position-abs.collapsible-filter .col-md-6,.position-abs.collapsible-filter .col-md-12 {
    padding: 0 8px;
}
label.radio-inline.d-flex.align-center span {
    margin-top: 3px;
}
.inline_label {
    position: absolute;
    top: -14px;
    height: 0;
    color: #666;
    z-index: 999;
    font-size: 12px;
    font-weight: 500;
}
.value {
    margin-top: 0;
    height: 34px;
}
.react-datepicker__input-container input {
    padding-top: 19px;
}
.date .inline_label {
    padding-left: 10px;
}
.calendar-icon {
    position: absolute;
    top: 23px;
    right: 24px;
    color: #666;
}
label.label_inline {
    position: absolute;
    z-index: 9999;
    font-weight: 500;
    font-size: 13px;
    color: #666;
    padding-left: 10px;

}

.custom-input-border > div {
    position: absolute;
    left: 17px;
    font-size: 12px;
    color: #666;
    font-weight: 500;
}
select.form-control {
    padding-top: 17px;
}
.custom-input-border {
    position: relative;
}
.single-search{
    margin: 10px 0;
}
.date .inline_label {
    top: 12px;
}
.single-search select, input[type="text"], .single-search input[type="search"] {
    box-shadow: 0 0 0
}
.single-search select, input[type="text"], .single-search input[type="search"] {

    border-radius: 4px;
}
.custom-buttons {
    margin-top: 3px;
}
.position-abs.collapsible-filter:before {
    position: absolute;
    top: -7px;
    width: 15px;
    height: 15px;
    background: #fff;
    content: "";
    right: 66px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
}
.position-abs.collapsible-filter button {
    padding: 10px 15px;
    border: none !important;
    margin-right: -5px;

}
.value {
    margin-top: 28px;
    height: 34px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.search-block .has-search input{
    border: 1px solid #999 !important;
    background: #fff;
}
.export-buttons .no-border {
    background: none;
    padding: 2px 10px;
    display: inline-block;
    color: #444;
    border: 1px solid #999;
    border-radius: 4px;
    margin-top: 24px;
    margin-right: 10px;
}
.export-buttons .no-border:hover{
    text-decoration: none;
}
thead th{
    background: #f5f6fa;
}
.carets {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    font-size: 10px;
    color: #999;
    margin-left: 4px;
    margin-top: 3px;
}
.float-left{
    float: left;
}

.dropdown-content{
    z-index: 9999999999 !important;
}
.dropdown-content label{
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: 400;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.label_inline {
    position: absolute;
    z-index: 9999;
    font-weight: 500;
    font-size: 13px;
    color: #666;
    padding-left: 10px;
}
.dropdown-heading-value {
    padding-top: 12px;
}
.dropdown-heading-value .gray {
    font-size: 12px;
    font-weight: 500;
}

.item-renderer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.filtering{
    position: absolute;
}
.react-datepicker-popper {
    z-index: 9999999;
}
