.table-bordered {
    border: 1px solid #999;
}
.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
    border: 1px solid #999;
}
.scroll {
    overflow: auto;
}


td.no-padding{
    padding: 0 !important;
}
.inner-table{
    border: none !important;
}
.inner-table th{
    border-bottom: none !important;
    border-left: none !important;
}
th {
    border-bottom: none !important;
}
.inner-table tr  th:last-child{
    border-right: none !important;
}
.inner-table td{
    border-bottom: none !important;
}
.inner-table td{
    border-left: none !important;
}
.custom-check-wrapper {
    width: 15px;
    height: 14px;
    box-shadow: 0 0 0 2px #999;
    margin-right: 10px;
    position: relative;
    margin: auto;
    margin-top: 5px;
    border-radius: 2px;
}
.custom-check-table {
    width: 10px;
    height: 18px;
    border-bottom: 2px solid red;
    border-right: 2px solid red;
    position: absolute;
    -webkit-transform: rotate(53deg);
    transform: rotate(53deg);
    left: 6px;
    top: -7px;
    display: none;
}
.custom-check-wrapper.shown .custom-check-table{
    display: block;
}
td.d-flex.align-center{
    border-bottom: none;
    border-right: none;
    border-left: none;
}
td.d-flex.align-center.no-border {
    border: none !important;
}
table.table.table-bordered {
    background: -webkit-linear-gradient(top,#f2f2f2a3, #fff);
    background: linear-gradient(to bottom,#f2f2f2a3, #fff);
}


.paginate {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.options{
    margin: 0 10px;
    font-weight: 600;
}
.justify-right{
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.filtering input.form-control{
    height: 35px;
}
.margin-top-100{
    margin-top: 100px;
}

.filtering {
    padding: 18px 10px;
    background: #fff;
}
.filtering .form-group{
    margin-bottom: 0;
}
.scroll-inner-table {
    max-height: 850px;
}
table.table.table-bordered.main-table {
    overflow: scroll;
}
.options {
    margin: 0 10px;
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.options input {
    width: 34px;
    text-align: center;
    border: 1px solid #ddd;
    margin: 0 2px;
}

.options .btn {
    padding: 0px 8px;
}
.per-page.d-flex.align-center {
    margin-top: 8px;
}

.background-white {
    background: white;
    box-shadow: 0 0 1px #999;
    border-radius: 5px;
    padding: 10px;
}
.chart-block {
    min-height: 309px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.vertical-horizontal-scroll {
    box-shadow: 0 0 1px #999;
    background: #ffff;
    padding: 8px;
}
.filtering {
    padding: 12px 10px;
    background: #fff;
    box-shadow: 0 0 1px #999;
}
.datewise-result table tr th {
    font-size: 14px;
    color: #444 !important;
}
.guide-text{
    margin: 15px 0;
}
.flex-center{
    display: flex;
    align-items: center;
}
.margin-left{
    margin-left: 20px;
}
.main-table td{
    min-width: 100px;
}

.d-inline{
    min-width: 50px;
    display: inline-block;
}
